<template>
  <div class="container">
    <a-page-header title="公司管理" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-form layout="inline" :form="form">
        <a-form-item label="法人">
          <a-input type="text" v-decorator="['real_name']" />
        </a-form-item>
        <a-form-item label="公司名称">
          <a-input type="text" v-decorator="['company_name']" />
        </a-form-item>
        <a-form-item label="状态">
          <a-select style="width: 170px" v-decorator="['status']" :allowClear="true">
            <a-select-option value="0">未认证</a-select-option>
            <a-select-option value="1">已认证</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleSearch">搜索</a-button>
          <a-button style="margin-left:5px;" @click="handleReset">重置</a-button>
        </a-form-item>
      </a-form>
      <a-button v-if="userInfo.role_id == 0" type="primary" @click="()=>{this.showExcelIn = true}">导入公司</a-button>
      <a-table
        :columns="columns"
        row-key="id"
        :data-source="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange">
          <template slot="type" slot-scope="text">
            {{text == 1 ? '小规模纳税人' : '一般纳税人'}}
          </template>
          <template slot="status" slot-scope="text">
            {{text == 1 ? '已认证' : '未认证'}}
          </template>
          <template slot="stop_status" slot-scope="text, item">
            {{text == 1 ? '启用' : '停用'}}
            <span v-if="text == 0">({{item.stop_reason}})</span>
          </template>
          <template slot="operate" slot-scope="text, item">
            <template v-if="item.stop_status == 1">
              <router-link class="ant-btn ant-btn-primary ant-btn-sm" :to="{path: '/index/userDetail', query: {id: item.id}}">查看</router-link>&nbsp;
              <a-popconfirm v-if="userInfo.role_id == 0" title="确定要重置密码吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="resetPassword(item)">
                <a-button type="danger" icon="reload" size="small">重置密码</a-button>
              </a-popconfirm>&nbsp;
              <a-popconfirm v-if="userInfo.role_id == 0" title="确定要停用该账户吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleStatusChange(item)">
                <a-button type="danger" size="small">停用</a-button>
              </a-popconfirm>
            </template>
            <template v-else>
              <a-popconfirm v-if="userInfo.role_id == 0" title="确定要启用该账户吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleStatusChange(item)">
                <a-button type="primary" size="small">启用</a-button>
              </a-popconfirm>
            </template>
          </template>
        </a-table>
    </div>
    <a-modal :visible="showExcelIn" title="一键导入" :footer="null" @cancel="()=>{this.showExcelIn = false}">
      <ExcelIn />
    </a-modal>
    <a-modal :visible="showInputStopReason" title="请输入停用原因"
      @ok="handleSureStopReason"
      @cancel="() => {this.showInputStopReason = false; this.inputStopReason = {id: '', value: ''}}">
      <a-input v-model="inputStopReason.value" />
    </a-modal>
  </div>
</template>

<script>

import { userList, userInfo } from '@/utils/api'
import ExcelIn from '../components/ExcelIn.vue'
import { mapState } from 'vuex'
const columns = [
  {
    title: '法人',
    dataIndex: 'real_name'
  },
  {
    title: '公司名称',
    dataIndex: 'company_name'
  },
  {
    title: '统一信用代码',
    dataIndex: 'credit_code'
  },
  {
    title: '公司类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '认证状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '账号状态',
    dataIndex: 'stop_status',
    scopedSlots: { customRender: 'stop_status' },
  },
  {
    title: '操作',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  }
]
export default {
  name: 'JiaList',
  data() {
    return {
      form: this.$form.createForm(this),
      tableParams: {},
      columns,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showTotal: total => `共${total}条数据`
      },
      loading: false,
      showExcelIn: false,
      showInputStopReason: false,
      inputStopReason: {
        id: '',
        value: ''
      }
    }
  },
  components: {
    ExcelIn
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    handleStatusChange(item) {
      const stop_status = item.stop_status == 1 ? 0 : 1
      if (stop_status == 1) {
        const params = {
          switch: 2,
          id: item.id,
          stop_status,
          stop_reason: ''
        }
        this.changeStatusFun(params)
      } else {
        this.showInputStopReason = true
        this.inputStopReason = {
          id: item.id,
          value: ''
        }
      }
    },
    handleSureStopReason() {
      const params = {
          switch: 2,
          id: this.inputStopReason.id,
          stop_status: 0,
          stop_reason: this.inputStopReason.value
        }
        this.changeStatusFun(params)
    },
    changeStatusFun (params) {
      userInfo(params).then(() => {
        this.showInputStopReason = false
        this.inputStopReason = {
          id: '',
          value: ''
        }
        this.$success({
          title: '提示',
          content: '操作成功'
        })
        this.getTableData()
      })
    },
    handleSearch() {
      this.form.validateFields((err, values) => {
        this.tableParams = { ...values }
        const pagination = { ...this.pagination }
        pagination.current = 1,
        this.pagination = pagination
        this.getTableData()
      })
    },
    handleReset() {
      this.form.setFieldsValue({
        real_name: '',
        company_name: '',
        status: ''
      })
      this.handleSearch()
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      const pagination = { ...this.pagination }
      userList({
        role_id: 1,
        pageCount: pagination.pageSize,
        page: pagination.current,
        ...this.tableParams
      }).then(res => {
        if (pagination.current == 1){
          pagination.total = res.count
          this.pagination = pagination
        }
        this.tableData = res.user
        this.loading = false
      })
    },
    resetPassword(item) {
      userInfo({
        switch: 3,
        id: item.id,
        password: '123456'
      }).then((res) => {
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: (
              <div>
                <p>密码已重置为：123456。</p>
                <p>为了保护用户的账号安全，请提示用户尽快修改密码！</p>
              </div>
            ),
            okText: '确定'
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
}
</style>
