<template>
  <div class="container">
    <a-upload
        name="file"
        :show-upload-list="false"
        action="/common/upload_excel"
        @change="handleExcelIn">
        <a-button><a-icon type="upload" />一键导入</a-button>
      </a-upload>
    <a-divider orientation="left">
      提示
    </a-divider>
    <p>请使用对应的表格模板，否则会出现严重的错误，无法批量导入！
      <a href="/common/storage?file_name=/app/public/角色添加模板.xlsx" target="_blank">下载模板</a>
    </p>
  </div>
</template>

<script>
import { excelIn } from '@/utils/api'
export default {
  name: 'ExcelIn',
  data () {
    return {
      
    }
  },
  mounted () {
    
  },
  methods: {
    handleExcelIn(res) {
      if (res.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (res.file.status === 'done') {
        const path = res.file.response.info
        excelIn({ path }).then((data) => {

          const h = this.$createElement
          let contentArr = []
          if (data.info) {
            contentArr = data.info.split('<br/>')
          } else {
            contentArr = ['导入成功']
          }
          const arr = []
          for (let index = 0; index < contentArr.length; index++) {
            const item = contentArr[index];
            arr.push(h('p', item))
          }
          this.$info({
            title: '提示',
            content: h('div', {}, arr)
          })
        })
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
